<template>
    <b-row>
        <b-col cols="12">
            <div class="d-flex align-items-center mb-4">
                <div style="width: 60%">
                    <b-input-group>
                        <b-form-input
                            id="filter-input"
                            type="text"
                            placeholder="Buscar registros"
                            class="bg-background-input"
                            v-model="search"
                            @keydown.enter="searchData"
                            @input="search === '' && searchData()"
                        ></b-form-input>

                        <b-input-group-append>
                            <b-button
                                variant="background-input"
                                class="d-flex align-items-center"
                                @click="searchData()"
                            >
                                <feather
                                    type="search"
                                    size="1.1rem"
                                    class="blue-active-text"
                                ></feather>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>
                <div class="ml-auto">
                    <div
                        class="d-sm-flex mt-4 mt-sm-0 justify-content-end align-items-center"
                    >
                        <input
                            type="file"
                            ref="excelInput"
                            id="excelInput"
                            style="display: none"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            @change="changeFile"
                        />
                        <b-button
                            variant="primary"
                            size="md"
                            class="d-flex align-items-center mt-4 mt-sm-0 mr-4"
                            @click="addRegister"
                            v-b-popover.hover.top="'Presione alt+a o option+a'"
                            :disabled="!permisos.includes('tercero-crear')"
                        >
                            <feather
                                type="plus"
                                size="1rem"
                                class="mr-2 blue-active-text"
                            ></feather>
                            Nuevo Registro
                        </b-button>
                        <b-dropdown
                            id="dropdown-divider"
                            text="Opciones"
                            size="md"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                            :disabled="
                                !permisos.includes('tercero-query-export') &&
                                !permisos.includes(
                                    'tercero-query-export-and-email'
                                ) &&
                                !permisos.includes('tercero-crud-export') &&
                                !permisos.includes('tercero-crud-import')
                            "
                        >
                            <template #button-content>
                                <div
                                    class="d-flex justify-content-center align-items-center"
                                >
                                    <feather
                                        type="more-horizontal"
                                        size="1.5rem"
                                        class="text-secondary"
                                    ></feather>
                                </div>
                            </template>
                            <b-dropdown-item-button
                                v-for="(format, index) in formats"
                                :key="index"
                                @click="verifyFields(format.ext)"
                                :disabled="
                                    !permisos.includes('tercero-query-export')
                                "
                            >
                                {{ format.name }}
                            </b-dropdown-item-button>
                            <b-dropdown-item-button
                                @click="$bvModal.show('email-modal')"
                                :disabled="
                                    !permisos.includes(
                                        'tercero-query-export-and-email'
                                    )
                                "
                                >Enviar informe por
                                Correo</b-dropdown-item-button
                            >
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item-button
                                @click="getImportFormat(moduleName)"
                                :disabled="
                                    !permisos.includes('tercero-crud-export')
                                "
                                >Plantilla para importación de
                                tabla</b-dropdown-item-button
                            >
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item-button
                                @click="$refs.excelInput.click()"
                                :disabled="
                                    !permisos.includes('tercero-crud-import')
                                "
                                >Cargar Plantilla para importación de
                                tabla</b-dropdown-item-button
                            >
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </b-col>
        <b-col cols="12" lg="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100 h-100">
                <div class="table-responsive mt-3" v-hotkey="keymap">
                    <b-table
                        :fields="fields"
                        :items="terceros"
                        :tbody-tr-class="rowClass"
                        borderless
                        responsive
                        head-variant="light"
                        class="mb-0 hover-table text-nowrap"
                    >
                        <template #cell(razon_social)="data">
                            <span>
                                {{
                                    data.item.tipo_documento_id === '31'
                                        ? data.value
                                        : `${data.item.nombre1} ${data.item.nombre2} ${data.item.apellido1} ${data.item.apellido2}`
                                }}
                            </span>
                        </template>
                        <!-- <template #cell(tipo_documento)="data">
                            <span
                                :class="`badge bg-secondary ${
                                    Theme === 'dark'
                                        ? 'text-gray'
                                        : 'text-white'
                                }`"
                                v-b-popover.hover.top="data.value.descripcion"
                            >
                                {{ data.value.alias }} -
                                {{ data.item.tipo_documento_id }}
                            </span>
                        </template> -->
                        <template #cell(n_documento)="data">
                            <div
                                :class="`badge bg-light-success ${
                                    Theme === 'dark' && 'text-gray'
                                }`"
                            >
                                {{
                                    data.item.tipo_documento_id !== '31'
                                        ? data.value
                                        : `${data.value}-${data.item.digito_verificacion}`
                                }}
                            </div>
                        </template>
                        <template #cell(municipio_id)="data">
                            <div>
                                {{ data.item.municipio.descripcion }}
                            </div>
                        </template>
                        <template #cell(celular)="data">
                            <div>
                                {{ data.item.celular }} /
                                {{ data.item.telefono }}
                            </div>
                        </template>
                        <template #cell(acciones)="data">
                            <feather
                                type="edit"
                                role="button"
                                class="mr-2"
                                v-if="permisos.includes('tercero-editar')"
                                v-b-popover.hover.top="'Editar'"
                                @click="
                                    $router.push({
                                        name: 'Editar Tercero',
                                        params: { tercero_id: data.item.id }
                                    })
                                "
                            ></feather>
                            <feather
                                type="edit"
                                role="button"
                                class="mr-2 text-secondary"
                                v-else
                                v-b-popover.hover.top="'Editar'"
                            ></feather>
                            <feather
                                type="trash-2"
                                role="button"
                                v-if="permisos.includes('tercero-eliminar')"
                                @click="showConfirm(data.item)"
                            ></feather>
                            <feather
                                type="trash-2"
                                role="button"
                                class="text-secondary"
                                v-else
                            ></feather>
                            <feather
                                type="eye"
                                role="button"
                                class="ml-2 mr-2"
                                v-b-popover.hover.top="'Ver'"
                                @click="
                                    $router.push({
                                        name: 'Ver Tercero',
                                        params: {
                                            tercero_id: data.item.id
                                        }
                                    })
                                "
                            ></feather>
                        </template>
                    </b-table>
                </div>
                <div class="p-3 d-flex align-items-center">
                    <b-form-group
                        label="Número de registros"
                        label-for="per-page-select"
                        label-size="sm"
                        label-class="fw-medium"
                        class="mb-0"
                    >
                        <b-form-select
                            id="per-page-select"
                            v-model="pagination.perPage"
                            :options="pagination.pageOptions"
                            size="sm"
                            @change="getTerceros('', 1, $event)"
                        ></b-form-select>
                    </b-form-group>
                    <div class="ml-auto">
                        <b-pagination
                            first-number
                            last-number
                            size="sm"
                            v-model="pagination.currentPage"
                            :total-rows="pagination.totalRows"
                            :per-page="pagination.perPage"
                            align="fill"
                            class="my-0"
                            @input="getTerceros('', $event, pagination.perPage)"
                        ></b-pagination>
                    </div>
                </div>
            </b-card>
            <selectFieldsReport
                :modulo="moduleName"
                :format="selectedFormat"
                :fields="
                    fieldsAvailables[
                        selectedFormat ? selectedFormat.toLowerCase() : 'excel'
                    ]
                "
                @close="selectedFormat = null"
            />
            <emailModal
                :sender="configEmail"
                :modulo="firstToUpperString(moduleName)"
                :fieldsAvailables="fieldsAvailables"
            />
        </b-col>
        <b-col cols="12">
            <div
                class="d-sm-flex mt-4 justify-content-between align-items-center"
            >
                <b-button
                    variant="primary"
                    size="md"
                    class="d-flex align-items-center mt-4 mt-sm-0 mr-4"
                    @click="addRegister"
                    v-b-popover.hover.top="'Presione alt+a o option+a'"
                    :disabled="!permisos.includes('tercero-crear')"
                >
                    <feather
                        type="plus"
                        size="1rem"
                        class="mr-2 blue-active-text"
                    ></feather>
                    Nuevo Registro
                </b-button>
                <b-dropdown
                    id="dropdown-divider"
                    text="Opciones"
                    size="md"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    :disabled="
                        !permisos.includes('tercero-query-export') &&
                        !permisos.includes('tercero-query-export-and-email') &&
                        !permisos.includes('tercero-crud-export') &&
                        !permisos.includes('tercero-crud-import')
                    "
                >
                    <template #button-content>
                        <div
                            class="d-flex justify-content-center align-items-center"
                        >
                            <feather
                                type="more-horizontal"
                                size="1.5rem"
                                class="text-secondary"
                            ></feather>
                        </div>
                    </template>
                    <b-dropdown-item-button
                        v-for="(format, index) in formats"
                        :key="index"
                        @click="verifyFields(format.ext)"
                        :disabled="!permisos.includes('tercero-query-export')"
                    >
                        {{ format.name }}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                        @click="$bvModal.show('email-modal')"
                        :disabled="
                            !permisos.includes('tercero-query-export-and-email')
                        "
                        >Enviar informe por Correo</b-dropdown-item-button
                    >
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item-button
                        @click="getImportFormat(moduleName)"
                        :disabled="!permisos.includes('tercero-crud-export')"
                        >Plantilla para importación de
                        tabla</b-dropdown-item-button
                    >
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item-button
                        @click="$refs.excelInput.click()"
                        :disabled="!permisos.includes('tercero-crud-import')"
                        >Cargar Plantilla para importación de
                        tabla</b-dropdown-item-button
                    >
                </b-dropdown>
            </div>
        </b-col>
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { mapGetters, mapState, mapActions } from 'vuex'
import selectFieldsReport from '@/components/shared/select-fields-report/select-fields-report'
import emailModal from '@/components/shared/email/email'
import {
    firstToUpperString,
    arrayErrorsToHtmlList,
    openNewHtmlContentWindow,
    removeListenerCommands,
    addListenerCommands
} from '@/utils/others'
import * as XLSX from 'xlsx'

const { API } = services

export default {
    name: 'MainTerceros',
    components: { selectFieldsReport, emailModal },
    mounted() {
        addListenerCommands(this)
        this.getTerceros()
        this.getEmpresaEmails()
        this.getAvailableFields()
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    data: () => ({
        fields: [
            { key: 'razon_social', label: 'Nombre o Razón social' },
            // { key: 'tipo_documento', label: 'Tipo documento' },
            { key: 'n_documento', label: 'Número documento' },
            { key: 'municipio_id', label: 'Ciudad' },
            { key: 'celular', label: 'Celular / Teléfono' },
            { key: 'acciones', label: 'Acciones' }
        ],
        terceros: [],
        pagination: {
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 20, 30, 40, 50, 100]
        },
        search: '',
        fieldsAvailables: {},
        configEmail: null,
        selectedFormat: null,
        moduleName: 'tercero',
        formats: [
            { id: 1, name: 'Generar Informe Excel', ext: 'EXCEL' },
            { id: 2, name: 'Generar Informe Pdf', ext: 'PDF' }
        ]
    }),
    methods: {
        showLog(data) {
            console.log(data)
        },
        firstToUpperString,
        setUltimaSedeAPI(sede) {
            API.POST({
                url: 'general/selec-ultima/sede/crud',
                data: {
                    p_datajson: {
                        sede_id: sede.id,
                        usuario_id: this.getUserData.id
                    },
                    p_opc: 'UPDATE',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.setUltimaSede(sede)
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        searchData() {
            this.getTerceros(this.search)
        },
        showConfirm({
            id,
            razon_social,
            nombre1,
            apellido1,
            tipo_documento_id
        }) {
            const name =
                tipo_documento_id === '31'
                    ? razon_social
                    : `${nombre1} ${apellido1}`
            this.$bvModal
                .msgBoxConfirm(
                    `Usted está apunto de eliminar al tercero "${name}", desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        this.deleteTercero(id)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        deleteTercero(id) {
            API.POST({
                url: 'general/tercero/crud',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'DELETE',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.getTerceros()
                        this.$bvModal
                            .msgBoxOk(mensaje || 'Eliminado correctamente', {
                                title: 'Exito!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'success',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'success',
                                headerClass: 'modal-header'
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getTerceros(term = '', page = 1, limit = this.pagination.perPage) {
            if (this.getUltimaEmpresa) {
                API.POST({
                    url: 'general/tercero/query',
                    data: {
                        p_datajson: {
                            page,
                            term,
                            limit,
                            empresa_id: this.getUltimaEmpresa.id
                        },
                        p_opc: 'FILTER',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato: { content, totalContent, size, number },
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            this.terceros = content
                            this.pagination = {
                                ...this.pagination,
                                totalRows: totalContent,
                                perPage: size,
                                currentPage: number
                            }
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 'active') return 'active'
        },
        verifyFields(ext) {
            this.selectedFormat = ext
            this.$bvModal.show('modal-fields-report')
        },
        getAvailableFields() {
            if (this.permisos.includes('tercero-query-export')) {
                API.POST({
                    url: 'report/available-fields',
                    data: {
                        p_datajson: {
                            modulo: this.moduleName
                        },
                        p_opc: 'GET_FIELD',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        // console.log(response.data)
                        if (status === 'ok') {
                            // console.log(dato);
                            this.fieldsAvailables = dato
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        changeFile(evt, validate) {
            const validar = !validate
            if (evt) {
                const file = evt.target.files[0]
                const reader = new FileReader()

                reader.onloadend = (e) => {
                    const data = e.target.result
                    const workbook = XLSX.read(data, { type: 'binary' })
                    const sheet = workbook.SheetNames[0]
                    const XL_ROW_OBJECT = XLSX.utils.sheet_to_json(
                        workbook.Sheets[sheet]
                    )
                    if (XL_ROW_OBJECT.length > 0) {
                        API.POST({
                            url: 'report/form/import',
                            data: {
                                p_datajson: {
                                    datos: [...XL_ROW_OBJECT],
                                    validar,
                                    empresa_id: this.getUltimaEmpresa.id
                                },
                                p_opc: this.moduleName.toUpperCase(),
                                p_auditoriajson: this.auditoriajson
                            }
                        })
                            .then((response) => {
                                const {
                                    data: {
                                        dato,
                                        status: {
                                            status,
                                            mensaje,
                                            mensaje_exception
                                        }
                                    }
                                } = response
                                if (!validar) {
                                    // console.log(dato)
                                    this.$refs.excelInput.value = null
                                    this.getTerceros()
                                } else {
                                    // console.log(response.data)
                                    if (status === 'ok' || status === 'warn') {
                                        if (status === 'warn') {
                                            this.$bvModal
                                                .msgBoxConfirm(
                                                    `Se importarán ${dato.exitosos} registros, ${dato.fallidos} registros contienen errores, desea continuar con la importación?`,
                                                    {
                                                        title: 'Advertencia!',
                                                        size: 'sm',
                                                        buttonSize: 'sm',
                                                        okVariant: 'danger',
                                                        okTitle: 'Continuar',
                                                        cancelTitle:
                                                            'Ver errores',
                                                        footerClass:
                                                            'p-2 d-flex justify-content-between',
                                                        hideHeaderClose: false,
                                                        centered: true,
                                                        headerBgVariant:
                                                            'danger',
                                                        headerClass:
                                                            'modal-header'
                                                    }
                                                )
                                                .then((value) => {
                                                    if (value) {
                                                        this.changeFile(
                                                            evt,
                                                            true
                                                        )
                                                    } else {
                                                        const htmlErrorList =
                                                            arrayErrorsToHtmlList(
                                                                dato.errores
                                                            )
                                                        openNewHtmlContentWindow(
                                                            htmlErrorList
                                                        )
                                                        this.$refs.excelInput.value =
                                                            null
                                                    }
                                                })
                                                .catch((err) => {
                                                    console.log(err)
                                                    this.$refs.excelInput.value =
                                                        null
                                                })
                                        } else {
                                            this.$bvModal
                                                .msgBoxConfirm(
                                                    `Se importarán ${dato.exitosos} registros, desea continuar con la importación?`,
                                                    {
                                                        title: 'Exito!',
                                                        size: 'sm',
                                                        buttonSize: 'sm',
                                                        okVariant: 'success',
                                                        okTitle: 'Continuar',
                                                        cancelTitle: 'Cancelar',
                                                        footerClass:
                                                            'p-2 d-flex justify-content-between',
                                                        hideHeaderClose: false,
                                                        centered: true,
                                                        headerBgVariant:
                                                            'success',
                                                        headerClass:
                                                            'modal-header'
                                                    }
                                                )
                                                .then((value) => {
                                                    if (value) {
                                                        this.changeFile(
                                                            evt,
                                                            true
                                                        )
                                                    } else {
                                                        this.$refs.excelInput.value =
                                                            null
                                                    }
                                                })
                                                .catch((err) => {
                                                    console.log(err)
                                                    this.$refs.excelInput.value =
                                                        null
                                                })
                                        }
                                    } else {
                                        this.$refs.excelInput.value = null
                                        this.$bvModal
                                            .msgBoxOk(
                                                mensaje_exception ||
                                                    mensaje ||
                                                    'Ha ocurrido un error!',
                                                {
                                                    title: 'Error!',
                                                    size: 'sm',
                                                    buttonSize: 'sm',
                                                    okVariant: 'error',
                                                    okTitle: 'Aceptar',
                                                    cancelTitle: 'Cancelar',
                                                    footerClass: 'p-2',
                                                    hideHeaderClose: false,
                                                    centered: true,
                                                    headerBgVariant: 'error',
                                                    headerClass: 'modal-header'
                                                }
                                            )
                                            .then(() => {
                                                // console.log(value);
                                            })
                                            .catch((err) => {
                                                console.log(err)
                                            })
                                    }
                                }
                            })
                            .catch((error) => {
                                this.$refs.excelInput.value = null
                                const { message } = error
                                this.$bvModal
                                    .msgBoxOk(
                                        message || 'Ha ocurrido un error!',
                                        {
                                            title: 'Error!',
                                            size: 'sm',
                                            buttonSize: 'sm',
                                            okVariant: 'error',
                                            okTitle: 'Aceptar',
                                            cancelTitle: 'Cancelar',
                                            footerClass: 'p-2',
                                            hideHeaderClose: false,
                                            centered: true,
                                            headerBgVariant: 'error',
                                            headerClass: 'modal-header'
                                        }
                                    )
                                    .then(() => {
                                        // console.log(value);
                                    })
                                    .catch((err) => {
                                        console.log(err)
                                    })
                            })
                    }
                    // console.log(workbook)
                }

                reader.readAsBinaryString(file)
            }
        },
        getEmpresaEmails() {
            API.POST({
                url: 'cartero/config-mail/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FIND_BY_EMPRESA',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    // console.log(response.data)
                    if (status === 'ok') {
                        this.configEmail = dato[0]
                    } else if (dato.length === 0 && status !== 'err') {
                        this.$bvModal
                            .msgBoxConfirm(
                                'Esta empresa no posee configuración de correo para reportes o envío de documentos por email, desea crear una configuración de correo para esta empresa?',
                                {
                                    title: 'Advertencia!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'warning',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'warning',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then((value) => {
                                if (value) {
                                    this.$router
                                        .push({
                                            path: '/home/general/generales/configuracion-email/crear-configuracion-email'
                                        })
                                        .catch((error) => error)
                                }
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        addRegister() {
            this.$router.push('/home/general/generales/terceros/crear-tercero')
        },
        ...mapActions(['getImportFormat'])
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'alt+a': this.addRegister
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'alt+a': this.addRegister
                }
            } else {
                return {}
            }
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 1 }
            return json
        },
        permisos() {
            return this.getPermisos.permisos_authorities
        },
        ...mapGetters('usuario', [
            'getUserData',
            'getAuditoriaJson',
            'getPermisos'
        ]),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapState(['Theme'])
    }
}
</script>

<style></style>
